import { ColumnDefinition } from "@tager/admin-ui";

import { ServiceCenterInferface } from "@/typings/model";
import { getServiceCentersUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ServiceCenterInferface>> = [
  {
    id: 1,
    name: "#",
    field: "index",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getServiceCentersUpdateUrl(row.id),
      },
    }),
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
  },
  {
    id: 4,
    name: "Адрес",
    field: "address",
  },
  {
    id: 5,
    name: "Бренды",
    field: "brands",
    type: "list",
  },
  {
    id: 5,
    name: "Услуги",
    field: "products",
    type: "list",
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
