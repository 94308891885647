import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { ServicePageFullInterface } from "@/typings/model";
import { ServicePageCreateOrUpdatePayload } from "@/services/service-pages";

export type FormValues = {
  service: Nullable<OptionType<Nullable<number>>>;
  brand: Nullable<OptionType<Nullable<number>>>;
  image: Nullable<SingleFileInputValueType>;
  preview: string;
  body: string;
  formTitle: string;
  ctaButtonLabel: string;
  ctaButtonLink: string;
  pageTitle: string;
  pageDescription: string;
  openGraphImage: Nullable<SingleFileInputValueType>;
};

export function getServicePageFormValues(
  model: Nullable<ServicePageFullInterface>,
  servicesList: OptionType<Nullable<number>>[],
  brandsList: OptionType<Nullable<number>>[]
): FormValues {
  if (!model) {
    return {
      service: null,
      brand: null,
      image: null,
      preview: "",
      body: "",
      formTitle: "",
      ctaButtonLink: "",
      ctaButtonLabel: "",
      pageTitle: "",
      pageDescription: "",
      openGraphImage: null,
    };
  }

  const brand = model.brand
    ? brandsList.find((brand) => brand.value === model.brand?.id)
    : null;

  const service = model.service
    ? servicesList.find((service) => service.value === model.service?.id)
    : null;

  return {
    service: service || null,
    brand: brand || null,
    image: model.image ? { id: createId(), file: model.image } : null,
    body: model.body || "",
    preview: model.preview || "",
    formTitle: model.formTitle || "",
    ctaButtonLink: model.ctaButtonLink || "",
    ctaButtonLabel: model.ctaButtonLabel || "",
    pageTitle: model.pageTitle || "",
    pageDescription: model.pageDescription || "",
    openGraphImage: model.openGraphImage
      ? { id: createId(), file: model.openGraphImage }
      : null,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): ServicePageCreateOrUpdatePayload {
  return {
    service: values.service ? values.service.value : null,
    brand: values.brand ? values.brand.value : null,
    image: values.image?.file.id ?? null,
    preview: values.preview,
    body: values.body,
    formTitle: values.formTitle,
    ctaButtonLabel: values.ctaButtonLabel,
    ctaButtonLink: values.ctaButtonLink,
    pageTitle: values.pageTitle,
    pageDescription: values.pageDescription,
    openGraphImage: values.openGraphImage?.file.id ?? null,
  };
}
