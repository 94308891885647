import { LinkType } from "@tager/admin-ui";
import { I18nContext } from "@tager/admin-services";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getExportListUrl } from "@tager/admin-export";
import { getImportListUrl } from "@tager/admin-import";
import { getSettingItemListUrl } from "@tager/admin-settings";

import {
  getBrandsListUrl,
  getCitiesListUrl,
  getProductsListUrl,
  getServiceCentersListUrl,
  getServicePagesListUrl,
  getServicesListUrl,
} from "@/utils/paths";

type LinkKey =
  | "HOME"
  | "SERVICES_LIST"
  | "SERVICE_PAGES_LIST"
  | "SERVICE_CENTERS_LIST"
  | "BRANDS_LIST"
  | "EXPORT"
  | "IMPORT"
  | "CITIES_LIST"
  | "PRODUCTS_LIST"
  | "SETTINGS_LIST"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS";

export const getLinks = (
  i18n: I18nContext
): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: i18n.t("admin:home"),
  },
  SERVICES_LIST: {
    url: getServicesListUrl(),
    text: "Сервисы",
  },
  SERVICE_PAGES_LIST: {
    url: getServicePagesListUrl(),
    text: "Сервисные страницы",
  },
  SERVICE_CENTERS_LIST: {
    url: getServiceCentersListUrl(),
    text: "Сервисные центры",
  },
  BRANDS_LIST: {
    url: getBrandsListUrl(),
    text: "Бренды",
  },
  CITIES_LIST: {
    url: getCitiesListUrl(),
    text: "Города",
  },
  PRODUCTS_LIST: {
    url: getProductsListUrl(),
    text: "Продукты",
  },
  SETTINGS_LIST: {
    url: getSettingItemListUrl(),
    text: "Настройки",
  },
  EXPORT: {
    url: getExportListUrl(),
    text: "Экспорт",
  },
  IMPORT: {
    url: getImportListUrl(),
    text: "Импорт",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: i18n.t("admin:roles"),
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: i18n.t("admin:administrators"),
  },
});
