import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getBrandsListUrl() {
  return ROUTE_PATHS.BRANDS_LIST;
}

export function getBrandsCreateUrl() {
  return compile(ROUTE_PATHS.BRANDS_FORM)({ id: "create" });
}

export function getBrandsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.BRANDS_FORM)({ id: id });
}

export function getCitiesListUrl() {
  return ROUTE_PATHS.CITIES_LIST;
}

export function getCitiesCreateUrl() {
  return compile(ROUTE_PATHS.CITIES_FORM)({ id: "create" });
}

export function getCitiesUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.CITIES_FORM)({ id: id });
}

export function getServiceCentersListUrl() {
  return ROUTE_PATHS.SERVICE_CENTERS_LIST;
}

export function getServiceCentersCreateUrl() {
  return compile(ROUTE_PATHS.SERVICE_CENTERS_FORM)({ id: "create" });
}

export function getServiceCentersUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.SERVICE_CENTERS_FORM)({ id: id });
}

export function getServicesListUrl() {
  return ROUTE_PATHS.SERVICES_LIST;
}

export function getServicesCreateUrl() {
  return compile(ROUTE_PATHS.SERVICES_FORM)({ id: "create" });
}

export function getServicesUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.SERVICES_FORM)({ id: id });
}

export function getServicePagesListUrl() {
  return ROUTE_PATHS.SERVICE_PAGES_LIST;
}

export function getServicePagesCreateUrl() {
  return compile(ROUTE_PATHS.SERVICE_PAGES_FORM)({ id: "create" });
}

export function getServicePagesUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.SERVICE_PAGES_FORM)({ id: id });
}

export function getProductsListUrl() {
  return ROUTE_PATHS.PRODUCTS_LIST;
}

export function getProductsCreateUrl() {
  return compile(ROUTE_PATHS.PRODUCTS_FORM)({ id: "create" });
}

export function getProductsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.PRODUCTS_FORM)({ id: id });
}
