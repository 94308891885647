import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a31930d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "names-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldUrlAliasInput = _resolveComponent("FormFieldUrlAliasInput")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_SeoFieldGroup = _resolveComponent("SeoFieldGroup")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: _ctx.isCreation ? 'Добавить бренд' : 'Редактировать бренд',
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["prevent"]))
      }, [
        (_ctx.selectedTabId === 'common')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                  name: "name",
                  error: _ctx.errors.name,
                  label: "Имя",
                  onInput: _ctx.handleTitleChange
                }, null, 8, ["value", "error", "onInput"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.nameRu,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.nameRu) = $event)),
                  name: "nameRu",
                  error: _ctx.errors.nameRu,
                  label: "Имя (Кириллица)"
                }, null, 8, ["value", "error"])
              ]),
              _createVNode(_component_FormFieldUrlAliasInput, {
                value: _ctx.values.urlAlias,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.urlAlias) = $event)),
                name: "urlAlias",
                error: _ctx.errors.urlAlias,
                label: "URL",
                "url-template": _ctx.websiteOrigin + '/',
                onChange: _ctx.handleAliasChange
              }, null, 8, ["value", "error", "url-template", "onChange"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.phone,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.phone) = $event)),
                name: "phone",
                error: _ctx.errors.phone,
                label: "Телефон"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.logo,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.logo) = $event)),
                name: "logo",
                error: _ctx.errors.logo,
                label: "Логотип (32 x 32)",
                "file-type": "image",
                scenario: "service-brand-logo"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_DynamicField, { field: _ctx.blogTags }, null, 8, ["field"]),
              _createVNode(_component_DynamicField, { field: _ctx.popularServices }, null, 8, ["field"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.selectedTabId === 'template')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.bannerImage,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.bannerImage) = $event)),
                name: "bannerImage",
                error: _ctx.errors.bannerImage,
                label: "Баннер - Картинка (1530 x 290)",
                "file-type": "image",
                scenario: "service-brand-banner"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.bannerImageMobile,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.bannerImageMobile) = $event)),
                name: "bannerImage",
                error: _ctx.errors.bannerImageMobile,
                label: "Баннер - Картинка (Mobile) (727 x 434)",
                "file-type": "image",
                scenario: "service-brand-banner"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.bannerTitle,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.bannerTitle) = $event)),
                name: "bannerTitle",
                error: _ctx.errors.bannerTitle,
                label: "Баннер - Заголовок"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.bannerText,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.bannerText) = $event)),
                name: "bannerText",
                error: _ctx.errors.bannerText,
                label: "Баннер - Текст",
                type: "textarea",
                rows: 4
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.statsTitleColor,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.statsTitleColor) = $event)),
                error: _ctx.errors.statsTitleColor,
                name: "statsTitleColor",
                label: "Статистика - Заголовок - Цвет",
                options: _ctx.statsTitleColorOptions
              }, null, 8, ["value", "error", "options"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.statsTitle,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.statsTitle) = $event)),
                name: "statsTitle",
                error: _ctx.errors.statsTitle,
                label: "Статистика - Заголовок"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.statsImage,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.statsImage) = $event)),
                name: "statsImage",
                error: _ctx.errors.statsImage,
                label: "Статистика - Картинка",
                "file-type": "image",
                scenario: "service-brand-stats"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.statsImageMobile,
                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.statsImageMobile) = $event)),
                name: "statsImage",
                error: _ctx.errors.statsImageMobile,
                label: "Статистика - Картинка (Mobile) (727 x 411)",
                "file-type": "image",
                scenario: "service-brand-stats"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_DynamicField, { field: _ctx.statsData }, null, 8, ["field"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.selectedTabId === 'seo')
          ? (_openBlock(), _createBlock(_component_SeoFieldGroup, {
              key: 2,
              title: _ctx.values.pageTitle,
              "title-error-message": _ctx.errors.pageTitle,
              description: _ctx.values.pageDescription,
              "description-error-message": _ctx.errors.pageDescription,
              image: _ctx.values.openGraphImage,
              "image-scenario": "open-graph",
              "image-error-message": _ctx.errors.openGraphImage,
              onChange: _ctx.handleSeoFieldGroupChange
            }, null, 8, ["title", "title-error-message", "description", "description-error-message", "image", "image-error-message", "onChange"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}