import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFieldRichTextInput = _resolveComponent("FormFieldRichTextInput")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_SeoFieldGroup = _resolveComponent("SeoFieldGroup")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: 
      _ctx.isCreation
        ? 'Добавить сервисную страницу'
        : 'Редактировать сервисную страницу'
    ,
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
      }, [
        (_ctx.selectedTabId === 'common')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.brand,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.brand) = $event)),
                name: "brand",
                searchable: true,
                error: _ctx.errors.brand,
                label: "Бренд",
                "no-options-message": "Нет брендов",
                placeholder: "Выберите бренд",
                options: _ctx.brandOptions
              }, null, 8, ["value", "error", "options"]),
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.service,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.service) = $event)),
                name: "service",
                searchable: true,
                error: _ctx.errors.service,
                label: "Сервис",
                "no-options-message": "Нет сервисов",
                placeholder: "Выберите сервис",
                options: _ctx.serviceOptions
              }, null, 8, ["value", "error", "options"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.selectedTabId === 'template')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.image,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.image) = $event)),
                name: "image",
                error: _ctx.errors.image,
                label: "Картинка (444 x 350)",
                "file-type": "image",
                scenario: "service-center"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldRichTextInput, {
                value: _ctx.values.preview,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.preview) = $event)),
                name: "preview",
                error: _ctx.errors.preview,
                label: "Верхний текст"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldRichTextInput, {
                value: _ctx.values.body,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.body) = $event)),
                name: "body",
                error: _ctx.errors.preview,
                label: "Основной текст"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.formTitle,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.formTitle) = $event)),
                name: "formTitle",
                error: _ctx.errors.formTitle,
                label: "Заголовок формы"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.ctaButtonLabel,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.ctaButtonLabel) = $event)),
                name: "ctaButtonLabel",
                error: _ctx.errors.ctaButtonLabel,
                label: "Call To Action - Текст кнопки"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.ctaButtonLink,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.ctaButtonLink) = $event)),
                name: "ctaButtonLink",
                error: _ctx.errors.ctaButtonLink,
                label: "Call To Action - Ссылка"
              }, null, 8, ["value", "error"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.selectedTabId === 'seo')
          ? (_openBlock(), _createBlock(_component_SeoFieldGroup, {
              key: 2,
              title: _ctx.values.pageTitle,
              "title-error-message": _ctx.errors.pageTitle,
              description: _ctx.values.pageDescription,
              "description-error-message": _ctx.errors.pageDescription,
              image: _ctx.values.openGraphImage,
              "image-scenario": "open-graph",
              "image-error-message": _ctx.errors.openGraphImage,
              onChange: _ctx.handleSeoFieldGroupChange
            }, null, 8, ["title", "title-error-message", "description", "description-error-message", "image", "image-error-message", "onChange"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}