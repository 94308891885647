export const EN = {
  pages: "Pages",
  settings: "Settings",
  common: "Common",
  seoSettings: "SEO Settings",
  seoTemplates: "SEO Templates",
  seoServices: "SEO Services",
  templates: "Templates",
  logs: "Logs",
  administrators: "Administrators",
  roles: "Roles",
  eMail: "E-Mail",
  home: "Home",
};
