import { request, ResponseBody } from "@tager/admin-services";

import { CityInterface } from "@/typings/model";

export interface CountData {
  count: number;
}

export interface CityCreateOrUpdatePayload {
  name: string;
}

export function getCitiesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/cities/count" });
}

export function getCities(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CityInterface>>> {
  return request.get({ path: "/admin/cities", params });
}

export function getCity(
  id: string | number
): Promise<ResponseBody<CityInterface>> {
  return request.get({ path: `/admin/cities/${id}` });
}

export function createCity(
  payload: CityCreateOrUpdatePayload
): Promise<ResponseBody<CityInterface>> {
  return request.post({
    path: `/admin/cities`,
    body: payload,
  });
}

export function updateCity(
  id: number | string,
  payload: CityCreateOrUpdatePayload
): Promise<ResponseBody<CityInterface>> {
  return request.put({
    path: `/admin/cities/${id}`,
    body: payload,
  });
}

export function deleteCity(id: number | string): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/cities/${id}` });
}

export function moveCity(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/cities/${id}/move/${direction}` });
}
