
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import { Page } from "@tager/admin-layout";
import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
  OptionType,
  getFilterParamAsStringArray,
  getFilterParams,
  FilterTagType,
  AdvancedSearch,
  FormFieldMultiSelect,
} from "@tager/admin-ui";
import { useResource, useResourceDelete } from "@tager/admin-services";

import {
  getServicePagesCreateUrl,
  getServicePagesUpdateUrl,
} from "@/utils/paths";
import { ServicePageInterface } from "@/typings/model";
import {
  getServicePagesList,
  deleteServicePage,
} from "@/services/service-pages";
import { getServicesList } from "@/services/services";
import { getBrandsList } from "@/services/brands";

import { COLUMN_DEFS } from "./ServicePagesList.helpers";

export default defineComponent({
  name: "ServicePagesList",
  components: {
    Page,
    DataTable,
    DeleteIcon,
    EditIcon,
    BaseButton,
    AdvancedSearch,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchDataList,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ServicePageInterface>({
      fetchEntityList: (params) =>
        getServicePagesList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список сервисных страниц",
    });

    const [
      fetchServiceList,
      { data: serviceList, loading: isServiceListLoading },
    ] = useResource({
      fetchResource: () =>
        getServicesList({
          pageSize: 10000,
          pageNumber: 1,
        }),
      initialValue: [],
      resourceName: "Service list",
    });

    const serviceOptionList = computed(() =>
      serviceList.value.map<OptionType>((service) => ({
        value: String(service.id),
        label: service.name,
      }))
    );

    const initialServiceFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "service");
      return serviceOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const serviceFilter = ref<Array<OptionType>>(initialServiceFilter.value);

    watch(initialServiceFilter, () => {
      serviceFilter.value = initialServiceFilter.value;
    });

    const [fetchBrandList, { data: brandList, loading: isBrandListLoading }] =
      useResource({
        fetchResource: () => getBrandsList(),
        initialValue: [],
        resourceName: "Brands list",
      });

    const brandOptionsList = computed(() =>
      brandList.value.map<OptionType>((brand) => ({
        value: String(brand.id),
        label: brand.name,
      }))
    );

    const initialBrandFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "brand");
      return brandOptionsList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const brandFilter = ref<Array<OptionType>>(initialBrandFilter.value);
    watch(initialBrandFilter, () => {
      brandFilter.value = initialBrandFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        brand: brandFilter.value.map((brand) => brand.value),
        service: serviceFilter.value.map((service) => service.value),
      });
    });

    watch(filterParams, () => {
      if (isBrandListLoading.value || isServiceListLoading.value) return;
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };
      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchDataList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "city") {
        serviceFilter.value = serviceFilter.value.filter(
          (service) => service.value !== event.value
        );
      }
      if (event.name === "brand") {
        brandFilter.value = brandFilter.value.filter(
          (brand) => brand.value !== event.value
        );
      }
    }

    const tags = computed<Array<FilterTagType>>(() => [
      ...serviceFilter.value.map((service) => ({
        value: service.value,
        label: service.label,
        name: "service",
        title: "Сервис",
      })),
      ...brandFilter.value.map((brand) => ({
        value: brand.value,
        label: brand.label,
        name: "brand",
        title: "Бренд",
      })),
    ]);

    onMounted(() => {
      fetchServiceList();
      fetchBrandList();
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteServicePage,
      resourceName: "Сервисный центр",
      onSuccess: fetchDataList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    const isRowDataLoading = computed<boolean>(
      () =>
        isDataLoading.value ||
        isServiceListLoading.value ||
        isBrandListLoading.value
    );

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getServicePagesCreateUrl,
      getServicePagesUpdateUrl,
      handleResourceDelete,

      serviceOptionList,
      brandOptionsList,
      tags,
      handleTagRemove,
      brandFilter,
      serviceFilter,
    };
  },
});
