import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { ServiceCenterFullInferface } from "@/typings/model";
import { ServiceCenterCreateOrUpdatePayload } from "@/services/service-centers";

export type FormValues = {
  name: string;
  image: Nullable<SingleFileInputValueType>;
  apiDealer: Nullable<OptionType<number>>;
  city: Nullable<OptionType<number>>;
  address: string;
  latitude: string;
  longitude: string;
  phone: string;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  brands: Array<OptionType<number>>;
  catalogBrands: Array<OptionType<number>>;
  isBodyRepairEnabled: boolean;
};

export function getServiceCenterFormValues(
  serviceCenter: Nullable<ServiceCenterFullInferface>,
  apiDealers: OptionType<number>[],
  citiesList: OptionType<number>[],
  brandsList: OptionType<number>[],
  catalogBrandsList: OptionType<number>[]
): FormValues {
  if (!serviceCenter) {
    return {
      name: "",
      image: null,
      apiDealer: null,
      city: null,
      address: "",
      latitude: "",
      longitude: "",
      phone: "",
      day1: "",
      day2: "",
      day3: "",
      day4: "",
      day5: "",
      day6: "",
      day7: "",
      brands: [],
      catalogBrands: [],
      isBodyRepairEnabled: true,
    };
  }

  const foundApiDealer = serviceCenter.apiDealer
    ? apiDealers.find(
        (apiDealer) => apiDealer.value === serviceCenter.apiDealer
      )
    : apiDealers[0];

  const foundCity = serviceCenter.city
    ? citiesList.find((city) => city.value === serviceCenter.city?.id)
    : null;

  const foundBrands = brandsList.filter((item) => {
    return serviceCenter.brands.map((item) => item.id).includes(item.value);
  });

  const foundCatalogBrands = catalogBrandsList.filter((item) => {
    return serviceCenter.catalogBrands.map((item) => item).includes(item.value);
  });

  return {
    name: serviceCenter.name || "",
    image: serviceCenter.image
      ? { id: createId(), file: serviceCenter.image }
      : null,
    city: foundCity || null,
    apiDealer: foundApiDealer || null,
    address: serviceCenter.address || "",
    phone: serviceCenter.phone || "",
    latitude: serviceCenter.latitude || "",
    longitude: serviceCenter.longitude || "",
    day1: serviceCenter.worktime[0] || "",
    day2: serviceCenter.worktime[1] || "",
    day3: serviceCenter.worktime[2] || "",
    day4: serviceCenter.worktime[3] || "",
    day5: serviceCenter.worktime[4] || "",
    day6: serviceCenter.worktime[5] || "",
    day7: serviceCenter.worktime[6] || "",
    brands: foundBrands,
    catalogBrands: foundCatalogBrands,
    isBodyRepairEnabled: serviceCenter.isBodyRepairEnabled,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): ServiceCenterCreateOrUpdatePayload {
  return {
    apiDealer: values.apiDealer ? values.apiDealer.value : null,
    name: values.name,
    image: values.image?.file.id ?? null,
    address: values.address,
    city: values.city ? values.city.value : null,
    phone: values.phone,
    latitude: parseFloat(values.latitude),
    longitude: parseFloat(values.longitude),
    worktime: [
      values.day1 || null,
      values.day2 || null,
      values.day3 || null,
      values.day4 || null,
      values.day5 || null,
      values.day6 || null,
      values.day7 || null,
    ],
    brands: values.brands.map((item) => item.value),
    catalogBrands: values.catalogBrands.map((item) => item.value),
    isBodyRepairEnabled: values.isBodyRepairEnabled,
  };
}
