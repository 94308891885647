
import { defineComponent } from "vue";

import { Page } from "@tager/admin-layout";
import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
  SouthIcon,
  NorthIcon,
  CountButton,
} from "@tager/admin-ui";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { CityInterface, ServiceInterface } from "@/typings/model";
import {
  getServicesUpdateUrl,
  getServicesCreateUrl,
  getServicePagesListUrl,
} from "@/utils/paths";
import {
  deleteService,
  getService,
  getServicesList,
  moveService,
} from "@/services/services";

import { COLUMN_DEFS } from "./ServicesList.helpers";

export default defineComponent({
  name: "ServicesList",
  components: {
    CountButton,
    Page,
    DataTable,
    SouthIcon,
    NorthIcon,
    DeleteIcon,
    EditIcon,
    BaseButton,
  },
  setup() {
    const {
      fetchEntityList: fetchServicesList,
      isLoading: isServicesListLoading,
      rowData: servicesListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ServiceInterface>({
      fetchEntityList: (params) =>
        getServicesList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список сервисов",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveService,
      resourceName: "Сервис",
      onSuccess: fetchServicesList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteService,
      resourceName: "Сервис",
      onSuccess: fetchServicesList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isServicesListLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: servicesListData,
      isRowDataLoading: isServicesListLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getServicesCreateUrl,
      getServicesUpdateUrl,
      getServicePagesListUrl,
      handleResourceDelete,
      handleResourceMove,
    };
  },
});
