
import {defineComponent, computed, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

import {
  convertRequestErrorToMap, navigateBack,
  Nullable,
  useResource, useToast,
} from '@tager/admin-services';

import {
  FormValues, convertPageFormValuesToCreationOrUpdatePayload, getCityFormValues,
} from './CitiesForm.helpers';

import {Page} from '@tager/admin-layout';
import {FormFooter, TagerFormSubmitEvent, FormField} from '@tager/admin-ui';
import {getCitiesListUrl, getCitiesUpdateUrl} from '@/utils/paths';
import {createCity, getCity, updateCity} from "@/services/cities";

export default defineComponent({
  name: 'CityForm',
  components: {Page, FormField, FormFooter},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchCity, {data: city, loading: isCityLoading}] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getCity(id.value);
        }

        return Promise.resolve({data: null});
      },
      initialValue: null,
      resourceName: "City",
    });

    onMounted(fetchCity);
    watch(id, fetchCity);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
        getCityFormValues(
            city.value,
        )
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getCityFormValues(city.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([city], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload = convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
      );

      const requestPromise = isCreation.value ? createCity(createOrUpdatePayload) :
          updateCity(id.value || "", createOrUpdatePayload);

      requestPromise.then((response) => {
        errors.value = {};

        if (event.type === "create") {
          router.push(getCitiesUpdateUrl(response.data.id));
        }

        if (event.type === "create_exit" || event.type === "save_exit") {
          navigateBack(router, getCitiesListUrl());
        }

        if (event.type === "create_create-another") {
          values.value = getCityFormValues(
              null,
          );
        }

        toast.show({
          variant: "success",
          title: "Города",
          body: isCreation.value
              ? "Город успешно создан"
              : "Город успешно обновлен"
        });
      })
          .catch((error) => {
            console.error(error);
            errors.value = convertRequestErrorToMap(error);
            toast.show({
              variant: "danger",
              title: "Города",
              body: isCreation.value
                  ? "Ошибка добавления города"
                  : "Ошибка изменения города"
            });
          })
          .finally(() => {
            isSubmitting.value = false;
          });
    }

    const isLoading = computed<boolean>(
        () => isCityLoading.value
    );

    return {
      isLoading,
      values,
      errors,
      city,
      isCreation,
      submitForm,
      backButtonUrl: getCitiesListUrl(),
      isSubmitting,
    };
  },
});
