import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  BrandInterface,
  ServiceFullInterface,
  ServiceInterface,
} from "@/typings/model";

export interface CountData {
  count: number;
}

export function getServicesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/services/count" });
}

export function getServicesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ServiceInterface>>> {
  return request.get({ path: "/admin/services", params });
}

export function getService(
  id: string | number
): Promise<ResponseBody<ServiceFullInterface>> {
  return request.get({ path: `/admin/services/${id}` });
}

export interface ServiceCreateOrUpdatePayload {
  name: string;
  blogTag: string;
  blogTitle: string;
  popular: boolean;
  noWebPage: boolean;
  urlAlias: string;
  image: Nullable<FileType["id"]>;
  parent: number | null;
  preview: string;
  body: string;
  leadType: string;
  formTitle: string;
  ctaButtonLabel: string;
  ctaButtonLink: string;
  pageTitle: string;
  pageBrandTitle: string;
  pageDescription: string;
  pageBrandDescription: string;
  openGraphImage: Nullable<FileType["id"]>;
}

export function createService(
  payload: ServiceCreateOrUpdatePayload
): Promise<ResponseBody<BrandInterface>> {
  return request.post({
    path: `/admin/services`,
    body: payload,
  });
}

export function updateService(
  id: number | string,
  payload: ServiceCreateOrUpdatePayload
): Promise<ResponseBody<BrandInterface>> {
  return request.put({
    path: `/admin/services/${id}`,
    body: payload,
  });
}

export function deleteService(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/services/${id}` });
}

export function moveService(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/services/${id}/move/${direction}` });
}
