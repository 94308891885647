
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  getNameWithDepth,
  navigateBack,
  Nullable,
  urlTranslit,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldSelect,
  FormFieldFileInput,
  FormFieldRichTextInput,
  OptionType,
  TabType,
  SeoChangeEvent,
  SeoFieldGroup,
  FormFieldUrlAliasInput,
  FormFieldCheckbox,
} from "@tager/admin-ui";

import {
  getServiceCentersListUrl,
  getServicesListUrl,
  getServicesUpdateUrl,
} from "@/utils/paths";
import {
  createService,
  getService,
  getServicesList,
  updateService,
} from "@/services/services";

import {
  FormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getServiceFormValues,
  serviceLeadTypeOptions,
} from "./ServicesForm.helpers";

export default defineComponent({
  name: "ServiceCentersForm",
  components: {
    FormFieldCheckbox,
    FormFieldUrlAliasInput,
    SeoFieldGroup,
    Page,
    FormField,
    FormFieldRichTextInput,
    FormFieldSelect,
    FormFieldFileInput,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const urlAliasChanged = ref(false);

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchService, { data: service, loading: isServiceLoading }] =
      useResource({
        fetchResource: () => {
          if (id.value && !isCreation.value) {
            return getService(id.value);
          }

          return Promise.resolve({ data: null });
        },
        initialValue: null,
        resourceName: "Service",
      });

    const [
      fetchServices,
      { data: servicesList, loading: isServicesListLoading },
    ] = useResource({
      fetchResource: () =>
        getServicesList({
          pageSize: 5000,
          pageNumber: 1,
        }),
      initialValue: [],
      resourceName: "Parent services list",
    });

    onMounted(() => {
      fetchService();
      fetchServices();
    });

    watch(id, fetchServices);

    const parentOptions = computed<Array<OptionType<Nullable<number>>>>(() => {
      const defaultParentOption = [
        { value: null, label: "Нет родительского сервиса" },
      ];

      const pageLists = servicesList.value
        .filter((_service) => _service.id !== service.value?.id)
        .map((service) => ({
          value: service.id,
          label: getNameWithDepth(service.name, service.depth),
        }));

      return [...defaultParentOption, ...pageLists];
    });

    watch([parentOptions], () => {
      updateFormValues();
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getServiceFormValues(service.value, parentOptions.value)
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getServiceFormValues(service.value, parentOptions.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([service], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createService(createOrUpdatePayload)
        : updateService(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getServicesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getServicesListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getServiceFormValues(null, parentOptions.value);
          }

          toast.show({
            variant: "success",
            title: "Сервисы",
            body: isCreation.value
              ? "Сервис успешно создан"
              : "Сервис успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Сервисы",
            body: isCreation.value
              ? "Ошибка добавления сервисного центра"
              : "Ошибка изменения сервисного центра",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isServiceLoading.value || isServicesListLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      return [
        { id: "common", label: "Основное" },
        { id: "template", label: "Содержание" },
        { id: "seo", label: "SEO" },
        { id: "seo_brand", label: "SEO - Бренд" },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    function handleSeoFieldGroupChange({
      title,
      description,
      image,
    }: SeoChangeEvent) {
      values.value.pageTitle = title;
      values.value.pageDescription = description;
      values.value.openGraphImage = image;
    }

    function handleSeoBrandFieldGroupChange({
      title,
      description,
    }: SeoChangeEvent) {
      values.value.pageBrandTitle = title;
      values.value.pageBrandDescription = description;
    }

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    function handleTitleChange(value: string) {
      if (isCreation.value) {
        if (urlAliasChanged.value === false) {
          values.value.urlAlias = urlTranslit(value);
        }
      }
    }

    function handleAliasChange() {
      urlAliasChanged.value = true;
    }

    const urlTemplate = computed(() => {
      if (values.value.parent) {
        const parentId = values.value.parent.value;
        const parent = servicesList.value.find(
          (_service) => _service.id === parentId
        );
        if (parent) {
          return websiteOrigin + parent.url.substring(8) + "/";
        }
      }
      return websiteOrigin + "/";
    });

    return {
      isLoading,
      values,
      errors,
      service,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getServiceCentersListUrl(),
      parentOptions,
      tabList,
      selectedTabId,
      handleSeoFieldGroupChange,
      handleSeoBrandFieldGroupChange,
      urlTemplate,
      handleAliasChange,
      handleTitleChange,
      serviceLeadTypeOptions,
    };
  },
  computed: {
    console: () => console,
  },
});
