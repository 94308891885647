import { ColumnDefinition } from "@tager/admin-ui";

import { BrandInterface } from "@/typings/model";
import { getBrandsUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<BrandInterface>> = [
  {
    id: 1,
    name: "#",
    field: "index",
    style: { width: "60px", textAlign: "center" },
    headStyle: { width: "60px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getBrandsUpdateUrl(row.id),
        subtext: row.nameRu || "",
      },
      websiteLink: {
        text: row.url,
        url: process.env.VUE_APP_WEBSITE_BASE_URL + row.url,
      },
    }),
  },
  {
    id: 3,
    name: "Логотип",
    field: "logo",
    type: "image",
    width: "120px",
  },
  {
    id: 4,
    name: "Телефон",
    field: "phone",
  },
  {
    id: 5,
    name: "",
    field: "relations",
    width: "480px",
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    width: "220px",
  },
];
