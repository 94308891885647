
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldSelect,
  FormFieldFileInput,
  FormFieldRichTextInput,
  OptionType,
  TabType,
  SeoFieldGroup,
  SeoChangeEvent,
  FormField,
} from "@tager/admin-ui";

import {
  getServiceCentersListUrl,
  getServicePagesListUrl,
  getServicePagesUpdateUrl,
} from "@/utils/paths";
import {
  createServicePage,
  getServicePage,
  updateServicePage,
} from "@/services/service-pages";
import { getServicesList } from "@/services/services";
import { getBrandsList } from "@/services/brands";

import {
  FormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getServicePageFormValues,
} from "./ServicePagesForm.helpers";

export default defineComponent({
  name: "ServicesPagesForm",
  components: {
    FormField,
    SeoFieldGroup,
    Page,
    FormFieldRichTextInput,
    FormFieldSelect,
    FormFieldFileInput,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [
      fetchServicePage,
      { data: servicePage, loading: isServicePageLoading },
    ] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getServicePage(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Service Page",
    });

    const [
      fetchServices,
      { data: servicesList, loading: isServicesListLoading },
    ] = useResource({
      fetchResource: () =>
        getServicesList({
          pageSize: 10000,
          pageNumber: 1,
        }),
      initialValue: [],
      resourceName: "Services list",
    });

    const [fetchBrands, { data: brandsList, loading: isBrandsListLoading }] =
      useResource({
        fetchResource: () => getBrandsList(),
        initialValue: [],
        resourceName: "Brands list",
      });

    onMounted(() => {
      fetchServicePage();
      fetchServices();
      fetchBrands();
    });

    watch(id, () => {
      fetchServices();
      fetchBrands();
    });

    const serviceOptions = computed<Array<OptionType<Nullable<number>>>>(() => {
      return servicesList.value.map((service) => ({
        value: service.id,
        label: service.name,
      }));
    });

    const brandOptions = computed<Array<OptionType<Nullable<number>>>>(() => {
      return brandsList.value.map((brand) => ({
        value: brand.id,
        label: brand.name,
      }));
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getServicePageFormValues(
        servicePage.value,
        serviceOptions.value,
        brandOptions.value
      )
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getServicePageFormValues(
        servicePage.value,
        serviceOptions.value,
        brandOptions.value
      );
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([serviceOptions, brandOptions, servicePage], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createServicePage(createOrUpdatePayload)
        : updateServicePage(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getServicePagesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getServicePagesListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getServicePageFormValues(
              null,
              serviceOptions.value,
              brandOptions.value
            );
          }

          toast.show({
            variant: "success",
            title: "Сервисная страница",
            body: isCreation.value
              ? "Сервисная страница успешно создана"
              : "Сервисная страница успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Сервисная страница",
            body: isCreation.value
              ? "Ошибка добавления сервисной страницы"
              : "Ошибка изменения сервисной страницы",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isServicePageLoading.value ||
        isServicesListLoading.value ||
        isBrandsListLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      return [
        { id: "common", label: "Основное" },
        { id: "template", label: "Содержание" },
        { id: "seo", label: "SEO" },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    function handleSeoFieldGroupChange({
      title,
      description,
      image,
    }: SeoChangeEvent) {
      values.value.pageTitle = title;
      values.value.pageDescription = description;
      values.value.openGraphImage = image;
    }

    return {
      isLoading,
      values,
      errors,
      servicePage,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getServiceCentersListUrl(),
      serviceOptions,
      brandOptions,
      tabList,
      selectedTabId,
      handleSeoFieldGroupChange,
    };
  },
  computed: {
    console: () => console,
  },
});
