import { Nullable } from "@tager/admin-services";

import { ProductInterface } from "@/typings/model";
import { ProductCreateOrUpdatePayload } from "@/services/products";

export type FormValues = {
  name: string;
  systemName: string;
};

export function getProductFormValues(
  product: Nullable<ProductInterface>
): FormValues {
  if (!product) {
    return {
      name: "",
      systemName: "",
    };
  }

  return {
    name: product.name || "",
    systemName: product.systemName || "",
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): ProductCreateOrUpdatePayload {
  return {
    name: values.name,
    systemName: values.systemName,
  };
}
