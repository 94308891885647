import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { ServiceFullInterface } from "@/typings/model";
import { ServiceCreateOrUpdatePayload } from "@/services/services";

export enum ServiceLeadType {
  Default = "DEFAULT",
  BodyRepair = "BODY_REPAIR",
}

export const serviceLeadTypeOptions: Array<OptionType<ServiceLeadType>> = [
  { value: ServiceLeadType.Default, label: "По-умолчанию" },
  { value: ServiceLeadType.BodyRepair, label: "Кузовной ремонт" },
];

export type FormValues = {
  name: string;
  popular: boolean;
  urlAlias: string;
  noWebPage: boolean;
  image: Nullable<SingleFileInputValueType>;
  parent: Nullable<OptionType<Nullable<number>>>;
  preview: string;
  body: string;
  leadType: OptionType<ServiceLeadType>;
  blogTag: string;
  blogTitle: string;
  formTitle: string;
  ctaButtonLabel: string;
  ctaButtonLink: string;
  pageTitle: string;
  pageBrandTitle: string;
  pageDescription: string;
  pageBrandDescription: string;
  openGraphImage: Nullable<SingleFileInputValueType>;
};

export function getServiceFormValues(
  service: Nullable<ServiceFullInterface>,
  parentsList: OptionType<Nullable<number>>[]
): FormValues {
  if (!service) {
    return {
      name: "",
      popular: false,
      noWebPage: false,
      image: null,
      urlAlias: "",
      parent: null,
      preview: "",
      body: "",
      leadType: serviceLeadTypeOptions[0],
      blogTag: "",
      blogTitle: "",
      formTitle: "",
      ctaButtonLabel: "",
      ctaButtonLink: "",
      pageTitle: "",
      pageBrandTitle: "",
      pageDescription: "",
      pageBrandDescription: "",
      openGraphImage: null,
    };
  }

  const parent = service.parent
    ? parentsList.find((parent) => parent.value === service.parent?.id)
    : parentsList[0];

  return {
    name: service.name || "",
    popular: service.popular,
    noWebPage: service.noWebPage,
    image: service.image ? { id: createId(), file: service.image } : null,
    urlAlias: service.urlAlias || "",
    parent: parent || null,
    body: service.body || "",
    leadType:
      serviceLeadTypeOptions.find((item) => item.value === service.leadType) ||
      serviceLeadTypeOptions[0],
    blogTag: service.blogTag || "",
    blogTitle: service.blogTitle || "",
    formTitle: service.formTitle || "",
    ctaButtonLabel: service.ctaButtonLabel || "",
    ctaButtonLink: service.ctaButtonLink || "",
    preview: service.preview || "",
    pageTitle: service.pageTitle || "",
    pageBrandTitle: service.pageBrandTitle || "",
    pageDescription: service.pageDescription || "",
    pageBrandDescription: service.pageBrandDescription || "",
    openGraphImage: service.openGraphImage
      ? { id: createId(), file: service.openGraphImage }
      : null,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): ServiceCreateOrUpdatePayload {
  return {
    name: values.name,
    blogTag: values.blogTag,
    blogTitle: values.blogTitle,
    popular: values.popular,
    noWebPage: values.noWebPage,
    urlAlias: values.urlAlias,
    image: values.image?.file.id ?? null,
    parent: values.parent ? values.parent.value : null,
    preview: values.preview,
    body: values.body,
    leadType: values.leadType.value,
    formTitle: values.formTitle,
    ctaButtonLabel: values.ctaButtonLabel,
    ctaButtonLink: values.ctaButtonLink,
    pageTitle: values.pageTitle,
    pageBrandTitle: values.pageBrandTitle,
    pageDescription: values.pageDescription,
    pageBrandDescription: values.pageBrandDescription,
    openGraphImage: values.openGraphImage?.file.id ?? null,
  };
}
