import { ColumnDefinition } from "@tager/admin-ui";

import { ServicePageInterface } from "@/typings/model";
import { getServiceCentersUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ServicePageInterface>> = [
  {
    id: 1,
    name: "#",
    field: "index",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Бренд",
    field: "brand",
  },
  {
    id: 3,
    name: "Услуга",
    field: "service",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.service,
        url: getServiceCentersUpdateUrl(row.id),
      },
      websiteLink: {
        text: row.url,
        url: process.env.VUE_APP_WEBSITE_BASE_URL + row.url,
      },
    }),
  },
  {
    id: 4,
    name: "Картинка",
    field: "image",
    type: "image",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
