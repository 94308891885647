import { ColumnDefinition } from "@tager/admin-ui";

import { ProductInterface } from "@/typings/model";

export const COLUMN_DEFS: Array<ColumnDefinition<ProductInterface>> = [
  {
    id: 1,
    name: "#",
    field: "index",
    style: { width: "60px", textAlign: "center" },
    headStyle: { width: "60px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
  },
  {
    id: 3,
    name: "",
    field: "relations",
    style: { width: "250px", textAlign: "center" },
    headStyle: { width: "250px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 4,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
