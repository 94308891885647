
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import { Page } from "@tager/admin-layout";
import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
  SouthIcon,
  NorthIcon,
  OptionType,
  getFilterParamAsStringArray,
  getFilterParams,
  FilterTagType,
  AdvancedSearch,
  FormFieldMultiSelect,
} from "@tager/admin-ui";
import {
  useResource,
  useResourceDelete,
  useResourceMove,
} from "@tager/admin-services";

import {
  getServiceCentersUpdateUrl,
  getServiceCentersCreateUrl,
} from "@/utils/paths";
import { ServiceCenterInferface } from "@/typings/model";
import {
  deleteServiceCenter,
  getServiceCentersList,
  moveServiceCenter,
} from "@/services/service-centers";
import { getCities } from "@/services/cities";
import { getBrandsList } from "@/services/brands";

import { COLUMN_DEFS } from "./ServiceCenters.helpers";

export default defineComponent({
  name: "ServiceCenters",
  components: {
    Page,
    DataTable,
    SouthIcon,
    NorthIcon,
    DeleteIcon,
    EditIcon,
    BaseButton,
    AdvancedSearch,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchServiceCentersList,
      isLoading: isServiceCentersListDataLoading,
      rowData: serviceCentersListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ServiceCenterInferface>({
      fetchEntityList: (params) =>
        getServiceCentersList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список сервисных центров",
    });

    const [fetchCityList, { data: cityList, loading: isCityListLoading }] =
      useResource({
        fetchResource: () => getCities(),
        initialValue: [],
        resourceName: "Cities list",
      });

    const cityOptionsList = computed(() =>
      cityList.value.map<OptionType>((city) => ({
        value: String(city.id),
        label: city.name,
      }))
    );

    const initialCityFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "city");
      return cityOptionsList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const cityFilter = ref<Array<OptionType>>(initialCityFilter.value);

    watch(initialCityFilter, () => {
      cityFilter.value = initialCityFilter.value;
    });

    const [fetchBrandList, { data: brandList, loading: isBrandListLoading }] =
      useResource({
        fetchResource: () => getBrandsList(),
        initialValue: [],
        resourceName: "Brands list",
      });

    const brandOptionsList = computed(() =>
      brandList.value.map<OptionType>((brand) => ({
        value: String(brand.id),
        label: brand.name,
      }))
    );

    const initialBrandFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "brand");
      return brandOptionsList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const brandFilter = ref<Array<OptionType>>(initialBrandFilter.value);
    watch(initialBrandFilter, () => {
      brandFilter.value = initialBrandFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        brand: brandFilter.value.map((brand) => brand.value),
        city: cityFilter.value.map((city) => city.value),
      });
    });

    watch(filterParams, () => {
      if (isBrandListLoading.value || isCityListLoading.value) return;
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };
      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchServiceCentersList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "city") {
        cityFilter.value = cityFilter.value.filter(
          (city) => city.value !== event.value
        );
      }
      if (event.name === "brand") {
        brandFilter.value = brandFilter.value.filter(
          (brand) => brand.value !== event.value
        );
      }
    }

    const tags = computed<Array<FilterTagType>>(() => [
      ...cityFilter.value.map((city) => ({
        value: city.value,
        label: city.label,
        name: "city",
        title: "Город",
      })),
      ...brandFilter.value.map((brand) => ({
        value: brand.value,
        label: brand.label,
        name: "brand",
        title: "Бренд",
      })),
    ]);

    onMounted(() => {
      fetchCityList();
      fetchBrandList();
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveServiceCenter,
      resourceName: "Сервисный центр",
      onSuccess: fetchServiceCentersList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteServiceCenter,
      resourceName: "Сервисный центр",
      onSuccess: fetchServiceCentersList,
    });

    function isBusy(id: number): boolean {
      return (
        isDeleting(id) || isMoving(id) || isServiceCentersListDataLoading.value
      );
    }

    const isRowDataLoading = computed<boolean>(
      () =>
        isServiceCentersListDataLoading.value ||
        isCityListLoading.value ||
        isBrandListLoading.value
    );
    return {
      columnDefs: COLUMN_DEFS,
      rowData: serviceCentersListData,
      isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getServiceCentersCreateUrl,
      getServiceCentersUpdateUrl,
      handleResourceDelete,
      handleResourceMove,

      cityOptionsList,
      brandOptionsList,
      tags,
      handleTagRemove,
      brandFilter,
      cityFilter,
    };
  },
});
