import { EN } from "./en";

export const RU: typeof EN = {
  pages: "Страницы",
  settings: "Настройки",
  common: "Общие",
  seoSettings: "SEO Настройки",
  seoTemplates: "SEO Шаблоны",
  seoServices: "SEO Сервисы",
  templates: "Шаблоны",
  logs: "Логи",
  administrators: "Администраторы",
  roles: "Роли",
  eMail: "E-Mail",
  home: "Главная",
};
