
import { computed, defineComponent, onMounted, watch } from "vue";

import { NavigationGrid, type NavigationGridItem } from "@tager/admin-ui";
import { useI18n, useResource, useUserStore } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getBrandsCount } from "@/services/brands";
import { getCitiesCount } from "@/services/cities";
import { getServiceCentersCount } from "@/services/service-centers";
import { getServicesCount } from "@/services/services";
import { getServicePagesCount } from "@/services/service-pages";
import { Scope } from "@/constants/scopes";
import { getProductsCount } from "@/services/products";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const i18n = useI18n();

    const [
      fetchBrandsCount,
      { data: brandsCountData, status: brandsCountDataStatus },
    ] = useResource({
      fetchResource: getBrandsCount,
      initialValue: null,
      resourceName: "Page count",
      scopes: [Scope.BrandsView],
    });

    const [
      fetchServicesCount,
      { data: servicesCountData, status: servicesCountDataStatus },
    ] = useResource({
      fetchResource: getServicesCount,
      initialValue: null,
      resourceName: "Services count",
      scopes: [Scope.ServicesView],
    });

    const [
      fetchCitiesCount,
      { data: citiesCountData, status: citiesCountDataStatus },
    ] = useResource({
      fetchResource: getCitiesCount,
      initialValue: null,
      resourceName: "Cities count",
      scopes: [Scope.CitiesView],
    });

    const [
      fetchServiceCentersCount,
      { data: serviceCentersCountData, status: serviceCentersCountDataStatus },
    ] = useResource({
      fetchResource: getServiceCentersCount,
      initialValue: null,
      resourceName: "Service Centers count",
      scopes: [Scope.ServiceCentersView],
    });

    const [
      fetchServicePagesCount,
      { data: servicePagesCountData, status: servicePagesCountDataStatus },
    ] = useResource({
      fetchResource: getServicePagesCount,
      initialValue: null,
      resourceName: "Service Pages count",
      scopes: [Scope.ServicePagesView],
    });

    const [
      fetchProductsCount,
      { data: productsCountData, status: productsCountDataStatus },
    ] = useResource({
      fetchResource: getProductsCount,
      initialValue: null,
      resourceName: "Products count",
      scopes: [Scope.ProductsView],
    });

    const userStore = useUserStore();

    const load = () => {
      fetchServicesCount();
      fetchBrandsCount();
      fetchCitiesCount();
      fetchServiceCentersCount();
      fetchServicePagesCount();
      fetchProductsCount();
    };
    watch(userStore, load);
    onMounted(load);

    const links = computed(() => getLinks(i18n));

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.SERVICES_LIST.text,
          url: links.value.SERVICES_LIST.url,
          total: {
            value: servicesCountData.value?.count ?? 0,
            status: servicesCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.ServicesView],
        },
        {
          name: links.value.SERVICE_PAGES_LIST.text,
          url: links.value.SERVICE_PAGES_LIST.url,
          total: {
            value: servicePagesCountData.value?.count ?? 0,
            status: servicePagesCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.ServicePagesView],
        },
        {
          name: links.value.SERVICE_CENTERS_LIST.text,
          url: links.value.SERVICE_CENTERS_LIST.url,
          total: {
            value: serviceCentersCountData.value?.count ?? 0,
            status: serviceCentersCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.ServiceCentersView],
        },
        {
          name: links.value.BRANDS_LIST.text,
          url: links.value.BRANDS_LIST.url,
          total: {
            value: brandsCountData.value?.count ?? 0,
            status: brandsCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.BrandsView],
        },
        {
          name: links.value.EXPORT.text,
          url: links.value.EXPORT.url,
          width: 50,
          scopes: [Scope.ExportServices],
        },
        {
          name: links.value.IMPORT.text,
          url: links.value.IMPORT.url,
          width: 50,
          scopes: [Scope.ImportServices],
        },
        {
          name: links.value.CITIES_LIST.text,
          url: links.value.CITIES_LIST.url,
          total: {
            value: citiesCountData.value?.count ?? 0,
            status: citiesCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.CitiesView],
        },
        {
          name: links.value.PRODUCTS_LIST.text,
          url: links.value.PRODUCTS_LIST.url,
          total: {
            value: productsCountData.value?.count ?? 0,
            status: productsCountDataStatus.value,
          },
          width: 50,
          scopes: [Scope.ProductsView],
        },
        {
          name: links.value.SETTINGS_LIST.text,
          url: links.value.SETTINGS_LIST.url,
          width: 50,
          scopes: [Scope.SettingsView],
        },
        {
          name: i18n.t("admin:administrators"),
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 50,
          scopes: [Scope.AdministratorsView],
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
