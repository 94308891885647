import { ColumnDefinition } from "@tager/admin-ui";

import { ServiceInterface } from "@/typings/model";
import { getServicesUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ServiceInterface>> = [
  {
    id: 1,
    name: "#",
    field: "index",
    style: { width: "60px", textAlign: "center" },
    headStyle: { width: "60px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getServicesUpdateUrl(row.id),
      },
      websiteLink: {
        text: row.url,
        url: row.noWebPage
          ? null
          : process.env.VUE_APP_WEBSITE_BASE_URL + row.url,
      },
    }),
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
  },
  {
    id: 5,
    name: "",
    field: "relations",
    style: { width: "270px", textAlign: "center" },
    headStyle: { width: "270px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
