import { request, ResponseBody } from "@tager/admin-services";

import { ProductInterface } from "@/typings/model";

export interface CountData {
  count: number;
}

export interface ProductCreateOrUpdatePayload {
  name: string;
  systemName: string;
}

export function getProductsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/products/count" });
}

export function getProductsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ProductInterface>>> {
  return request.get({ path: "/admin/products", params });
}

export function getProduct(
  id: string | number
): Promise<ResponseBody<ProductInterface>> {
  return request.get({ path: `/admin/products/${id}` });
}

export function createProduct(
  payload: ProductCreateOrUpdatePayload
): Promise<ResponseBody<ProductInterface>> {
  return request.post({
    path: `/admin/products`,
    body: payload,
  });
}

export function updateProduct(
  id: number | string,
  payload: ProductCreateOrUpdatePayload
): Promise<ResponseBody<ProductInterface>> {
  return request.put({
    path: `/admin/products/${id}`,
    body: payload,
  });
}

export function deleteProduct(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/products/${id}` });
}
