import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  ServicePageFullInterface,
  ServicePageInterface,
} from "@/typings/model";

export interface CountData {
  count: number;
}

export function getServicePagesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/service-pages/count" });
}

export function getServicePagesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ServicePageInterface>>> {
  return request.get({ path: "/admin/service-pages", params });
}

export function getServicePage(
  id: string | number
): Promise<ResponseBody<ServicePageFullInterface>> {
  return request.get({ path: `/admin/service-pages/${id}` });
}

export interface ServicePageCreateOrUpdatePayload {
  service: number | null;
  brand: number | null;
  image: Nullable<FileType["id"]>;
  preview: string;
  body: string;
  formTitle: string;
  ctaButtonLabel: string;
  ctaButtonLink: string;
  pageTitle: string;
  pageDescription: string;
  openGraphImage: Nullable<FileType["id"]>;
}

export function createServicePage(
  payload: ServicePageCreateOrUpdatePayload
): Promise<ResponseBody<ServicePageFullInterface>> {
  return request.post({
    path: `/admin/service-pages`,
    body: payload,
  });
}

export function updateServicePage(
  id: number | string,
  payload: ServicePageCreateOrUpdatePayload
): Promise<ResponseBody<ServicePageFullInterface>> {
  return request.put({
    path: `/admin/service-pages/${id}`,
    body: payload,
  });
}

export function deleteServicePage(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/service-pages/${id}` });
}
