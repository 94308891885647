
import { defineComponent } from "vue";

import { Page } from "@tager/admin-layout";
import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
  NorthIcon,
  SouthIcon,
  CountButton,
  ContentCopyIcon,
} from "@tager/admin-ui";
import {
  useResourceClone,
  useResourceDelete,
  useResourceMove,
  useUserPermission,
} from "@tager/admin-services";

import {
  cloneBrand,
  deleteBrand,
  getBrandsList,
  moveBrand,
} from "@/services/brands";
import { BrandInterface } from "@/typings/model";
import { COLUMN_DEFS } from "@/views/BrandsList/BrandsList.helpers";
import { Scope } from "@/constants/scopes";
import {
  getBrandsCreateUrl,
  getBrandsUpdateUrl,
  getServiceCentersListUrl,
  getServicePagesListUrl,
} from "@/utils/paths";

export default defineComponent({
  name: "BrandsList",
  components: {
    Page,
    DataTable,
    BaseButton,
    DeleteIcon,
    EditIcon,
    NorthIcon,
    SouthIcon,
    CountButton,
    ContentCopyIcon,
  },
  setup() {
    const {
      fetchEntityList: fetchBrandList,
      isLoading: isBrandListDataLoading,
      rowData: brandListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<BrandInterface>({
      fetchEntityList: (params) =>
        getBrandsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список брендов",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveBrand,
      resourceName: "Бренд",
      onSuccess: fetchBrandList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteBrand,
      resourceName: "Бренд",
      onSuccess: fetchBrandList,
    });

    const { isCloning, handleResourceClone } = useResourceClone({
      cloneResource: cloneBrand,
      confirmMessage: "Вы уверены, что хотите клонировать бренд?",
      successMessage: "Бренд успешно склонирован",
      failureMessage: "Ошибка клонирования бренда",
      onSuccessRedirectTo: (data: BrandInterface) => "/brands/" + data.id,
    });

    function isBusy(id: number): boolean {
      return (
        isDeleting(id) ||
        isMoving(id) ||
        isCloning(id) ||
        isBrandListDataLoading.value
      );
    }

    const canCreateBrand = useUserPermission(Scope.BrandsCreate);
    const canEditBrand = useUserPermission(Scope.BrandsEdit);
    const canDeleteBrand = useUserPermission(Scope.BrandsDelete);
    const canServiceCentersView = useUserPermission(Scope.ServiceCentersView);
    const canServicePagesView = useUserPermission(Scope.ServicePagesView);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: brandListData,
      isRowDataLoading: isBrandListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      handleResourceClone,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getBrandsCreateUrl,
      getBrandsUpdateUrl,
      getServiceCentersListUrl,
      getServicePagesListUrl,
      handleResourceDelete,
      handleResourceMove,
      canCreateBrand,
      canEditBrand,
      canDeleteBrand,
      canServiceCentersView,
      canServicePagesView,
    };
  },
});
