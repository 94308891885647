import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c9dfc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Сервисные страницы",
    "header-buttons": [
      {
        text: 'Создать сервисную страницу',
        href: _ctx.getServicePagesCreateUrl(),
      },
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageSize: _ctx.pageSize,
        pageCount: _ctx.pageCount,
        pageNumber: _ctx.pageNumber,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.brandFilter,
                  "onUpdate:selected-options": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brandFilter) = $event)),
                  options: _ctx.brandOptionsList,
                  name: "brandFilter",
                  label: "Бренд",
                  "container-class": "filter"
                }, null, 8, ["selected-options", "options"]),
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.serviceFilter,
                  "onUpdate:selected-options": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.serviceFilter) = $event)),
                  options: _ctx.serviceOptionList,
                  name: "serviceFilter",
                  label: "Сервис",
                  "container-class": "filter"
                }, null, 8, ["selected-options", "options"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(index)": _withCtx(({ rowIndex }) => [
          _createTextVNode(_toDisplayString(rowIndex + 1), 1)
        ]),
        "cell(actions)": _withCtx(({ row, rowIndex }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            disabled: _ctx.isBusy(row.id),
            href: _ctx.getServicePagesUpdateUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Удалить",
            disabled: _ctx.isBusy(row.id),
            onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}