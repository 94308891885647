import {Nullable} from "@tager/admin-services";
import {CityInterface} from "@/typings/model";
import {CityCreateOrUpdatePayload} from "@/services/cities";

export type FormValues = {
    name: string;
};

export function getCityFormValues(
    city: Nullable<CityInterface>,
): FormValues {
    if (!city) {
        return {
            name: ''
        };
    }

    return {
        name: city.name || ''
    };
}


export function convertPageFormValuesToCreationOrUpdatePayload(
    values: FormValues
): CityCreateOrUpdatePayload {
    return {
        name: values.name
    }
}